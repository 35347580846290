import React from 'react';
import { Layout, Menu, Breadcrumb, Button } from 'antd';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import {
  DashboardOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  WalletOutlined,
  TwitterOutlined,
  AppstoreOutlined,
  MessageOutlined
} from '@ant-design/icons';
import { useLogout } from '../pages/login';

const { Content, Sider } = Layout;

const BasicLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();

  const menuItems = [
    {
      key: '/dashboard',
      icon: <DashboardOutlined />,
      label: '仪表盘',
    },
    {
      key: '/user',
      icon: <UserOutlined />,
      label: '用户管理',
    },
    {
      key: '/permission',
      icon: <UserOutlined />,
      label: '权限管理',
    },
    {
      key: '/wallet',
      icon: <WalletOutlined />,
      label: '钱包管理',
    },
    {
      key: '/twitter',
      icon: <TwitterOutlined />,
      label: '推特管理',
    },
    {
      key: '/discord',
      icon: <MessageOutlined />,
      label: 'Discord管理',
    },
    {
      key: '/galaxy',
      icon: <AppstoreOutlined />,
      label: '银河账号管理',
    },
    {
      key: '/galaxy-tasks',
      icon: <AppstoreOutlined />,
      label: '银河任务池',
    },
    {
      key: '/settings',
      icon: <SettingOutlined />,
      label: '系统设置',
    },
  ];

  const handleMenuClick = ({ key }) => {
    navigate(key);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider>
        <div style={{ height: 32, margin: 16, background: 'rgba(255, 255, 255, 0.2)' }} />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}
          items={menuItems}
          onClick={handleMenuClick}
        />
      </Sider>
      <Layout>
        <Content style={{ margin: '0', padding: '24px', background: '#f0f2f5' }}>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            marginBottom: '16px', 
            background: '#fff', 
            padding: '8px 16px', 
            borderRadius: '4px'
          }}>
            <Breadcrumb>
              <Breadcrumb.Item>首页</Breadcrumb.Item>
              {location.pathname === '/dashboard' && <Breadcrumb.Item>仪表盘</Breadcrumb.Item>}
              {location.pathname === '/user' && <Breadcrumb.Item>用户管理</Breadcrumb.Item>}
              {location.pathname === '/twitter' && <Breadcrumb.Item>推特管理</Breadcrumb.Item>}
              {location.pathname === '/discord' && <Breadcrumb.Item>Discord管理</Breadcrumb.Item>}
              {location.pathname === '/galaxy' && <Breadcrumb.Item>银河账号管理</Breadcrumb.Item>}
              {location.pathname === '/galaxy-tasks' && <Breadcrumb.Item>银河任务池</Breadcrumb.Item>}
              {location.pathname === '/settings' && <Breadcrumb.Item>系统设置</Breadcrumb.Item>}
            </Breadcrumb>
            <Button 
              type="link" 
              onClick={logout}
              icon={<LogoutOutlined />}
            >
              退出登录
            </Button>
          </div>
          <div style={{ padding: '24px', background: '#fff', borderRadius: '4px' }}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default BasicLayout;