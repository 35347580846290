import React from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { login } from '../../api/user';

const LoginPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const result = await login(values);
      
      if (result.status === 'success' && result.code === 200) {
        localStorage.setItem('token', result.data.user_id); 
        message.success('登录成功');
        navigate('/dashboard');
        saveUserPermissions(result);
      } else {
        message.error(result.message || '登录失败');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || '登录失败，请稍后重试';
      message.error(errorMessage);
      console.error('登录错误:', error);
    }
  };

  const saveUserPermissions = (loginData) => {
    // 保存完整的权限数组
    localStorage.setItem('permissions', JSON.stringify(loginData.data.permissions))
    
    // 也可以只保存权限码数组,方便后续判断
    const permissionCodes = loginData.data.permissions.map(p => p.code)
    localStorage.setItem('permissionCodes', JSON.stringify(permissionCodes))
  }

  return (
    <div style={{ 
      height: '100vh', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      background: '#f0f2f5'
    }}>
      <Card style={{ width: 400, padding: '24px' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '24px' }}>系统登录</h2>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label="用户名"
            name="username"
            rules={[{ required: true, message: '请输入用户名' }]}
          >
            <Input placeholder="请输入用户名" />
          </Form.Item>

          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true, message: '请输入密码' }]}
          >
            <Input.Password placeholder="请输入密码" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              登录
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export const useLogout = () => {
  const navigate = useNavigate();
  
  return () => {
    localStorage.removeItem('token');
    message.success('已退出登录');
    navigate('/login');
  };
};

export default LoginPage; 